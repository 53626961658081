import React from "react";
import { Navbar, Nav, Container, Offcanvas } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL_IMAGE, Images, ROUTENAME } from "../../helper/helper";
import { Link } from "react-router-dom";

const NavBar = () => {
  const dispatch = useDispatch();
  const { name, image } = useSelector((state) => state.userReducer.userData);
  const userData = useSelector((state) => state.userReducer.userData);
  console.log("userData", image);

  return (
    <>
      <div className="Navbar">
        <div className="container">
          <Navbar key={"md"} expand={"md"}>
            <Container fluid>
              <Navbar.Brand href="/">
                <img
                  className="nav-img"
                  src="/assets/images/logo-nav.png"
                  alt=""
                />
              </Navbar.Brand>
              <Navbar.Brand href="/">
                <img
                  className="nav-img"
                  style={{
                    background: "white",
                    borderRadius: "5px",
                    padding: "2px",
                  }}
                  src="https://studycaller.com/assets/images/logo/ca_logo.png"
                  alt=""
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${"md"}`} />
              <Navbar.Offcanvas
                style={{ background: "#446fd1" }}
                id={`offcanvasNavbar-expand-${"md"}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${"md"}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <img
                    className="nav-img"
                    src="/assets/images/logo-nav.png"
                    alt=""
                  />
                  <img
                    className="nav-img"
                    src="https://studycaller.com/assets/images/logo/ca_logo.png"
                    alt=""
                  />
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3 fw-bolder">
                    <Nav.Link
                      href="https://studycaller.com/"
                      className="text-light mt-2"
                    >
                      Home
                    </Nav.Link>
                    <Nav.Link
                      href="https://studycaller.com/notes/subject.php"
                      className="text-light mt-2"
                    >
                      Notes
                    </Nav.Link>
                    <Nav.Link
                      href="https://studycaller.com/about-us.php"
                      className="text-light mt-2"
                    >
                      About
                    </Nav.Link>
                    <Nav.Link href="/" className="text-light mt-2">
                      Store
                    </Nav.Link>
                    <Nav.Link href="/cart" className="text-light mt-2">
                      Cart
                    </Nav.Link>
                    <Nav.Link
                      href="https://studycaller.com/contact.php"
                      className="text-light mt-2"
                    >
                      Contact
                    </Nav.Link>

                    <Nav.Link
                      className={`text-light ${image == undefined && "mt-2"}`}
                    >
                      <Link
                        to={
                          userData == ""
                            ? ROUTENAME.LoginSCreen
                            : ROUTENAME.ProfileScreen
                        }
                        style={{ color: "white", textDecorationLine: "none" }}
                      >
                        {image != undefined ? (
                          <img
                            src={
                              userData?.image != ""
                                ? BASE_URL_IMAGE + userData?.image
                                : userData.gender === "0"
                                ? Images.MaleDummyImage
                                : Images.FemaleDummyImage
                            }
                            style={{
                              width: 50,
                              height: 50,
                              borderRadius: 50 / 2,
                              border: "2px solid white",
                            }}
                          />
                        ) : (
                          "Login/Signup"
                        )}
                      </Link>
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </div>
      </div>
    </>
  );
};

export default NavBar;
