import React from "react";
import { Colors } from "../helper/helper";

const StarContainer = ({ value, onvalue, onPress, size = 20 }) => {
  return (
    <i
      type={"btn"}
      onClick={onPress}
      class={
        Number(value) <= Number(onvalue)
          ? "fa-solid fa-star"
          : "fa-regular fa-star"
      }
      style={{
        color: Colors.RED_COLOR,
      }}
    ></i>
  );
};

export default StarContainer;
