import { ApiEndPoint } from "../../helper/helper";
import { METHODS } from "../../helper/helper";
import { fetchData } from "../../helper/ApiCall";

/////////////////////////////////////
export const GoogleSignupHandler = async (data) => {
  try {
    var formdata = new FormData();

    formdata.append("name", data.name);
    formdata.append("email", data.email);
    formdata.append("googleId", data.id);
    formdata.append("device", "web");

    const Result = await fetchData(
      ApiEndPoint.GOOGLE_SIGN_UP,
      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};
export const LoginHandler = async (mobile) => {
  try {
    var formdata = new FormData();
    formdata.append("mobile", mobile);
    formdata.append("device", "web");

    const Result = await fetchData(ApiEndPoint.login, METHODS.POST, formdata);
    return Result;
  } catch (error) {
    console.log("error", error);
  }
};
export const LoginVerificationHandler = async (mobile, otp) => {
  try {
    var formdata = new FormData();
    formdata.append("mobile", mobile);
    formdata.append("otp", otp);
    formdata.append("device", "web");

    const Result = await fetchData(
      ApiEndPoint.loginverify,
      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    console.log("error", error);
  }
};
export const SignupHandler = async (username, name, mobile, gender, image) => {
  try {
    var formdata = new FormData();
    formdata.append("username", username);
    formdata.append("name", name);
    formdata.append("mobile", mobile);
    formdata.append("gender", gender);
    formdata.append("image", image);

    const Result = await fetchData(ApiEndPoint.signup, METHODS.POST, formdata);

    return Result;
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

export const MobileVerificationHandler = async (
  mobile,
  otp,
  fcm_token,
  device
) => {
  try {
    var formdata = new FormData();
    formdata.append("mobile", mobile);
    formdata.append("verification_code", otp);
    formdata.append("fcm", fcm_token);
    formdata.append("device", device);

    const Result = await fetchData(
      ApiEndPoint.verifymobile,

      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    console.log("error", error);
  }
};
