import React from "react";
import { Colors } from "../../helper/helper";
import StarContainer from "../StarContainer";
import moment from "moment";

const ReviewBox = ({ item, index }) => {
  return (
    <div
      style={{
        marginBottom: 20,
        marginTop: index == 0 ? 20 : 0,
      }}
    >
      <div
        style={{
          display: "flex",
          alignSelf: "center",
        }}
      >
        <div
          style={{
            width: 50,
            height: 50,
            borderRadius: 50,
            backgroundColor: Colors.BorderColor_2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p style={{ margin: 0 }}>
            {item?.student_name != undefined && item.student_name.slice(0, 1)}
          </p>
        </div>
        <div
          style={{
            width: "83%",
            marginLeft: 20,
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <StarContainer value={1} onvalue={item.star} />
              <StarContainer value={2} onvalue={item.star} />
              <StarContainer value={3} onvalue={item.star} />
              <StarContainer value={4} onvalue={item.star} />
              <StarContainer value={5} onvalue={item.star} />
              <p
                style={{
                  margin: 0,

                  marginLeft: 10,
                }}
              >
                {moment(new Date(item.create_at)).fromNow()}
              </p>
            </div>
            <p
              style={{
                color: Colors.TextPrimary,
                marginLeft: 4,
                marginTop: 4,
              }}
            >
              {item.student_name}
            </p>
          </div>
        </div>
      </div>
      <p
        style={{
          margin: 0,
          color: Colors.TextPrimary,
          marginLeft: 4,
        }}
      >
        {item.title}
      </p>
      <p
        style={{
          margin: 0,
          marginLeft: 4,
        }}
      >
        {item.review}
      </p>
    </div>
  );
};

export default ReviewBox;
