import React, { useEffect, useState } from "react";

import "./CartScreen.css";
import {
  FailedOrderStoreCartData,
  RemoveProductInCart,
  GetStoreCartData,
  OrderStoreCartData,
  RemoveStudentAddress,
  GetStudentAddressData,
  ADDStudentAddress,
  GetOrderIdForRzerpay,
  GetStateListData,
} from "./index";
import { useSelector, useDispatch } from "react-redux";
import {
  addstoreCartitem,
  removestoreCartitem,
  addallstoreCartdata,
  storecartEmpty,
} from "../../redux/reducer/cartReducer";
// import CartItem from "../../container/CartItem/CartItem";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";
import {
  Colors,
  RAZERPAY_API_KEY,
  RAZERPAY_SCERET_KEY,
  BASE_URL_IMAGE,
} from "../../helper/helper";
import useRazorpay from "react-razorpay";
const CartScreen = () => {
  const [Razorpay] = useRazorpay();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userReducer.userData);
  const {
    storecartData,
    storesubtotal_price,
    storetotal_price,
    storediscount_price,
  } = useSelector((state) => state.cartReducer);

  const [subtotal, setsubtotal] = useState();
  const [total, settotal] = useState();
  const [discount, setdiscount] = useState();
  const [addressData, setaddressData] = useState();
  const [selectedaddress, setselectedaddress] = useState();
  const [email, setemail] = useState();
  const [mobile, setmobile] = useState();
  const [loader, setloader] = useState(false);
  const [detailshow, setdetailshow] = useState(false);
  const [addadreesshow, setaddadreesshow] = useState(false);
  const [statedata, setstatedata] = useState();
  const [selectedsate, setselectedsate] = useState();
  const [userAddress, setuserAddress] = useState({
    email: "",
    mobile: "",
    address_one: "",
    address_two: "",
    landmark: "",
    pincode: "",
    city: "",
    state: "",
    type: "",
    addressId: "",
  });
  useEffect(() => {
    setsubtotal(storesubtotal_price);
    settotal(storetotal_price);
    setdiscount(storediscount_price);

    if (storecartData.length == 0) {
      handler();
    }
  }, [storesubtotal_price, storetotal_price, storediscount_price]);
  useEffect(() => {
    GetAddresshandler();
    stateListhandler();
  }, []);
  const handler = async () => {
    try {
      const result = await GetStoreCartData();

      if (result.status == true) {
        dispatch(addallstoreCartdata(result.data));
      }
    } catch (err) {
      console.log(err);
    }
  };
  const GetAddresshandler = async () => {
    try {
      const result = await GetStudentAddressData();
      console.log(result);
      if (result.status == true) {
        setaddressData(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const DeleteAddresshandler = async (id) => {
    try {
      const result = await RemoveStudentAddress(id);
      console.log(result);
      if (result.status == true) {
        const newArray = addressData.filter((item) => {
          return item.id != id;
        });
        newArray.length == 0 ? setaddressData() : setaddressData(newArray);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const RemovetoCartHandler = async (item) => {
    dispatch(removestoreCartitem(item));
    try {
      const Result = await RemoveProductInCart(item.product);
      console.log(Result);
      if (Result.status == false) {
        dispatch(addstoreCartitem(item));
      }
    } catch (err) {
      console.log(err);
      dispatch(addstoreCartitem(item));
    }
  };

  const validate = () => {
    if (userData.mobile == null && mobile.length != 10) {
      return "Enter valid number";
    } else if (userData.email == null && email == "") {
      return "Enter email";
    } else {
      return true;
    }
  };
  const cartorderidcreate = async () => {
    try {
      const Result = await GetOrderIdForRzerpay(total);
      console.log(Result);

      if (Result?.id != undefined) {
        CheckoutHandler(Result);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const CheckoutHandler = async (data) => {
    var options = {
      description: "Credits towards StudyCaller",
      image:
        userData.image != ""
          ? BASE_URL_IMAGE + userData.image
          : userData.gender === "0"
          ? BASE_URL_IMAGE + "images/male-user.png"
          : BASE_URL_IMAGE + "images/female-user.png",
      currency: "INR",
      key: RAZERPAY_API_KEY,
      amount: data.amount,
      name: userData.name,

      order_id: data.id, //Replace this with an order_id created using Orders API.
      prefill: {
        email: userData.email,
        contact: userData.mobile,
        name: userData.name,
        // name: "Gaurav Kumar", //your customer's name
        // email: "gaurav.kumar@example.com",
        // contact: "9000090000", //Provide the customer's phone number for
      },
      theme: { color: Colors.PrimaryColor },
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
        BuyCartDatahandler(
          response.razorpay_order_id,
          response.razorpay_payment_id
        );
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      FailedBuyhandler();
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  };
  const BuyCartDatahandler = async (orderId = "", paymentId = "") => {
    setloader(true);
    try {
      const newArray = [];
      storecartData.map((item) => {
        newArray.push(...item["product-detail"]);
      });
      const json = {
        product_detail: newArray,
        user_address: selectedaddress,
      };
      const Result = await OrderStoreCartData(
        orderId,
        paymentId,
        storesubtotal_price,
        storediscount_price,
        storetotal_price,
        json
      );
      console.log(Result);
      if (Result.status == true) {
        dispatch(storecartEmpty());
        setloader(false);
        // setpaymentdone(true);
      } else {
        alert(Result.message);
      }
    } catch (err) {
      console.log(err);
      setloader(false);
    }
  };
  const FailedBuyhandler = async () => {
    try {
      const productId = storecartData.map((item) => {
        return item.product;
      });
      const Result = await FailedOrderStoreCartData(
        productId,
        storetotal_price
      );
    } catch (err) {
      console.log(err);
    }
  };
  const stateListhandler = async () => {
    try {
      const result = await GetStateListData();

      if (result.status == true) {
        setstatedata(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const useraddresshandler = async (e) => {
    setuserAddress({
      ...userAddress,
      [e.target.name]: e.target.value,
    });
  };
  const validateUserAddress = () => {
    if (userAddress.mobile == "" && userAddress.mobile.length != 10) {
      return "Enter valid number";
    } else if (userAddress.email == "") {
      return "Enter email";
    } else if (userAddress.type == "") {
      return "Enter address type";
    } else if (userAddress.pincode == "") {
      return "Enter pincode";
    } else if (userAddress.address_one == "") {
      return "Enter address_one";
    } else if (userAddress.address_two == "") {
      return "Enter address_two";
    } else if (userAddress.city == "") {
      return "Enter city";
    } else if (userAddress.state == "") {
      return "Enter state";
    } else if (userAddress.landmark == "") {
      return "Enter landmark";
    } else {
      return true;
    }
  };

  const handlePressadduseraddress = async () => {
    const validation = validateUserAddress();
    if (validation == true) {
      try {
        const result = await ADDStudentAddress(userAddress, selectedsate);

        if (result.status == true) {
          // setstatedata(result.data);
          setaddadreesshow(false);
          setdetailshow(true);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      alert(validation);
    }
  };
  const CartItem = ({ item, productDetail, index, onPress }) => {
    return (
      <div
        className="product-wedgits"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="">
          <p>{productDetail?.name}</p>
          <p>₹ {productDetail?.final_price}</p>
          {productDetail?.price != productDetail?.final_price && (
            <p style={{ textDecorationLine: "line-through" }}>
              ₹ {productDetail?.price}
            </p>
          )}
        </div>
        <i onClick={onPress} type={"btn"} className="fa-solid fa-trash"></i>
      </div>
    );
  };

  const PriceBox = ({ title, price }) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p>{title}</p>
        <p>₹{price}</p>
      </div>
    );
  };

  return (
    <>
      <div className="main">
        <div className="container">
          <div className="pt-2 pb-3">
            <div className="alert alert-success " role="alert">
              You Saved {discount} in this order
            </div>

            <Row>
              <Col
                md={8}
                style={{
                  backgroundColor: Colors.WHITE,
                  padding: 20,
                  borderRadius: 10,
                }}
              >
                <p>Your Products</p>
                {storecartData.length > 0 &&
                  storecartData.map((item, index) => {
                    return (
                      item["product-detail"].length > 0 && (
                        <CartItem
                          key={index}
                          item={item}
                          productDetail={item["product-detail"][0]}
                          index={index}
                          onPress={() =>
                            Swal.fire({
                              title: "Are you sure?",
                              text: `You won't to delete ${item["product-detail"][0].name} from cart!`,
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: "Yes, delete it!",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                RemovetoCartHandler(item);
                                Swal.fire(
                                  "Deleted!",
                                  `${item["product-detail"][0].name} has been deleted.`,
                                  "success"
                                );
                              }
                            })
                          }
                        />
                      )
                    );
                  })}
              </Col>
              <Col md={4}>
                <div
                  style={{
                    backgroundColor: Colors.WHITE,
                    padding: 20,
                    borderRadius: 10,
                  }}
                >
                  <PriceBox title={"Sub Total"} price={subtotal} />
                  <PriceBox title={"Discount"} price={-+discount} />
                  <PriceBox title={"Total"} price={total} />
                  <Button
                    style={{ width: "100%" }}
                    onClick={() => {
                      if (selectedaddress == undefined) {
                        if (addressData != undefined) {
                          setdetailshow(true);
                        } else {
                          setaddadreesshow(true);
                        }
                      } else {
                        cartorderidcreate();
                      }
                    }}
                  >
                    {selectedaddress == undefined ? "Next" : "Buy Now"}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Modal
        show={addadreesshow}
        onHide={() => setaddadreesshow(!addadreesshow)}
        className="spinner-modal"
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Add address </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="mt-2">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              {/* <Form.Control type="email" placeholder="Select Address type" /> */}
              <Form.Select
                name="type"
                value={userAddress.type}
                onChange={useraddresshandler}
              >
                <option>Select address type</option>
                <option value={"Home"}>Home</option>
                <option value={"Work"}>Work</option>
              </Form.Select>

              <div style={{ height: 10 }} />
              <Form.Control
                name="email"
                value={userAddress?.email}
                onChange={useraddresshandler}
                type="email"
                placeholder="Enter Emai"
              />
              <div style={{ height: 10 }} />

              <Form.Control
                name="mobile"
                value={userAddress?.mobile}
                onChange={useraddresshandler}
                placeholder="Enter Phone Number"
              />
              <div style={{ height: 10 }} />

              <Form.Control
                name="address_one"
                value={userAddress?.address_one}
                onChange={useraddresshandler}
                placeholder="Flat, House no., Building, Company"
              />
              <div style={{ height: 10 }} />

              <Form.Control
                name="address_two"
                value={userAddress?.address_two}
                onChange={useraddresshandler}
                placeholder="Area, Street, Sector, Village"
              />
              <div style={{ height: 10 }} />

              <Form.Control
                name="landmark"
                value={userAddress?.landmark}
                onChange={useraddresshandler}
                placeholder="Enter Landmark"
              />
              <div style={{ height: 10 }} />
              <Form.Control
                name="city"
                value={userAddress?.city}
                onChange={useraddresshandler}
                placeholder="Enter Town/City"
              />
              <div style={{ height: 10 }} />
              <Form.Control
                name="pincode"
                value={userAddress?.pincode}
                onChange={useraddresshandler}
                placeholder="Enter Pincode"
              />
              <div style={{ height: 10 }} />
              <Form.Select
                name="state"
                value={userAddress?.state}
                onChange={(e) => {
                  const itemValue = e.target.value;
                  const res = statedata.find((item) => {
                    return item.name == itemValue;
                  });
                  setselectedsate(res?.stateId);
                  setuserAddress({
                    ...userAddress,
                    state: itemValue,
                  });
                }}
                placeholder="Enter state"
              >
                <option>Select state</option>
                {statedata != undefined &&
                  statedata.map((item, index) => {
                    return (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
              </Form.Select>
              <div style={{ height: 10 }} />
            </Form.Group>

            <button
              className="btn btn-submit "
              type="btn"
              onClick={handlePressadduseraddress}
            >
              Submit
            </button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={detailshow}
        onHide={() => setdetailshow(!detailshow)}
        className="spinner-modal"
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title> Select address </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addressData != undefined &&
            addressData.map((item, index) => {
              return (
                <div key={index}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      type="btn"
                      onClick={() => {
                        setselectedaddress(item);
                        // setdetailshow(false);
                      }}
                      style={{
                        width: 20,
                        height: 20,
                        borderRadius: 20,
                        border: `1px solid ${Colors.PrimaryColor}`,
                        marginRight: 10,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {selectedaddress?.id == item.id && (
                        <div
                          style={{
                            width: 12,
                            height: 12,
                            borderRadius: 12,
                            backgroundColor: Colors.PrimaryColor,
                          }}
                        />
                      )}
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <p>{item.type}</p>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <p
                            onClick={() => {
                              setuserAddress({
                                ...userAddress,
                                email: item.email,
                                mobile: item.phone,
                                address_one: item.address_one,
                                address_two: item.address_two,
                                landmark: item.landmark,
                                pincode: item.pincode,
                                city: item.city,
                                state: item.stateName,
                                type: item.type,
                                addressId: item.addressId,
                              });
                              setselectedsate(item.state);

                              setdetailshow(false);
                              setaddadreesshow(true);
                            }}
                            style={{
                              marginRight: 20,
                              color: Colors.PrimaryColor,
                            }}
                          >
                            Edit
                          </p>
                          <p
                            type="btn"
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: `You won't to delete ${item.type} address!`,
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  DeleteAddresshandler(item.id);
                                  Swal.fire(
                                    "Deleted!",
                                    `${item.type} has been deleted.`,
                                    "success"
                                  );
                                }
                              });
                            }}
                            style={{
                              color: Colors.RED_COLOR,
                            }}
                          >
                            Delete
                          </p>
                        </div>
                      </div>
                      <p>
                        {item.address_one +
                          ", " +
                          item.address_two +
                          ", " +
                          item.landmark +
                          ", " +
                          item.city +
                          ", " +
                          item.stateName +
                          ", " +
                          item.pincode +
                          ", " +
                          "India"}
                      </p>
                    </div>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: 1,
                      backgroundColor: Colors.BorderColor_3,
                      marginBottom: 10,
                    }}
                  />
                </div>
              );
            })}
          {}
          <Button
            onClick={() => {
              if (selectedaddress != undefined) {
                cartorderidcreate();
              } else {
                Swal.fire({
                  title: "Select Address",
                  icon: "warning",
                  showCancelButton: true,
                  cancelButtonText: "Ok",
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes",
                  showConfirmButton: false,
                });
              }
            }}
          >
            Buy Now
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CartScreen;
