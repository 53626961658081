import React, { useState } from "react";
import ReviewBox from "./ReviewBox";
import { Button, Col, Form } from "react-bootstrap";
import StarContainer from "../StarContainer";
import { Colors } from "../../helper/helper";

const ReviewsContainer = ({
  reviewData,
  star,
  starHanlder = () => {},
  review_title,
  review_titleHanlder = () => {},
  review_description,
  review_descriptionHanlder = () => {},
  addproductreviewHandler,
}) => {
  const [addreviewShow, setaddreviewShow] = useState(false);

  return (
    <div
      style={{
        paddingTop: 10,
      }}
    >
      <h4>Customers Reviews</h4>
      {addreviewShow == true || reviewData == undefined ? (
        <Col sm={4}>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Review Title</Form.Label>
              <Form.Control
                value={review_title}
                placeholder="Review Title"
                onChange={(text) => {
                  review_titleHanlder(text.target.value);
                }}
              />
            </Form.Group>

            <p style={{ margin: 0 }}>Rating</p>
            <div>
              <StarContainer
                onPress={() => {
                  starHanlder(1);
                }}
                value={1}
                onvalue={star}
              />
              <StarContainer
                onPress={() => {
                  starHanlder(2);
                }}
                value={2}
                onvalue={star}
              />
              <StarContainer
                onPress={() => {
                  starHanlder(3);
                }}
                value={3}
                onvalue={star}
              />
              <StarContainer
                onPress={() => {
                  starHanlder(4);
                }}
                value={4}
                onvalue={star}
              />
              <StarContainer
                onPress={() => {
                  starHanlder(5);
                }}
                value={5}
                onvalue={star}
              />
            </div>

            <Form.Group
              className="mb-3 mt-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Review</Form.Label>
              <Form.Control
                value={review_description}
                as="textarea"
                rows={3}
                onChange={(text) => {
                  review_descriptionHanlder(text.target.value);
                }}
              />
            </Form.Group>
          </Form>
          <Button
            style={{
              width: "100%",
              height: 50,
              backgroundColor: Colors.RED_COLOR,
              marginTop: 20,
              borderWidth: 0,
            }}
            onClick={addproductreviewHandler}
          >
            SUBMIT REVIEW
          </Button>
        </Col>
      ) : (
        <Button
          style={{
            width: "20%",
            height: 50,
            backgroundColor: Colors.RED_COLOR,
            marginTop: 5,
            borderWidth: 0,
          }}
          onClick={() => {
            setaddreviewShow(true);
          }}
        >
          ADD REVIEW
        </Button>
      )}
      {reviewData != undefined &&
        reviewData.map((item, index) => {
          return <ReviewBox key={index} item={item} index={index} />;
        })}
    </div>
  );
};

export default ReviewsContainer;
