import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BASE_URL_IMAGE, Colors, Images, ROUTENAME } from "../../helper/helper";
import { Button, Col, Row } from "react-bootstrap";
import { storecartEmpty } from "../../redux/reducer/cartReducer";
import {
  logout,
  userdataUpdateAnyKeyValue,
} from "../../redux/reducer/userReducer";
import { Link, useNavigate } from "react-router-dom";
import "./ProfileScreen.css";
import { UpdateProfileCoverPic, UpdateProfilePic } from ".";

const ProfileScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userData = useSelector((state) => state.userReducer.userData);

  const [editShow, seteditShow] = useState(false);

  // useEffect(() => {
  //   if (prfilecoverpic) {
  //     const objectURL = URL.createObjectURL(prfilecoverpic);
  //     setDefaultUserImage(objectURL);
  //     return () => URL.revokeObjectURL(objectURL);
  //   }
  // }, [prfilecoverpic]);
  // useEffect(() => {
  //   if (prfilepic) {
  //     const objectURL = URL.createObjectURL(prfilepic);
  //     setprfilepic(objectURL);
  //     return () => URL.revokeObjectURL(objectURL);
  //   }
  // }, [prfilepic]);
  const inputFile = useRef(null);
  const onButtonClickSelectImage = (event) => {
    inputFile.current.click();
  };
  const handleChangeimage = (event) => {
    const fileUploaded = event.target.files[0];

    handlerProfile(fileUploaded);
  };

  const hiddenFileInput = useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];

    handlerProfileCover(fileUploaded);
  };

  const handlerProfile = async (image) => {
    try {
      const Result = await UpdateProfilePic(image);
      console.log(Result);
      if (Result.status == true) {
        dispatch(
          userdataUpdateAnyKeyValue({
            key: "image",
            value: Result.image,
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handlerProfileCover = async (image) => {
    try {
      const Result = await UpdateProfileCoverPic(image);
      console.log("dcdscds", Result);

      if (Result.status == true) {
        dispatch(
          userdataUpdateAnyKeyValue({
            key: "cover_image",
            value: Result.cover_image,
          })
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="main">
      <div style={{}}>
        <div style={{
          overflow:"hidden",
            maxHeight: "50vh",
          }}>
        <img
          src={
            userData?.cover_image == ""
              ? Images.UserBackgroundcoverimage
              : BASE_URL_IMAGE + userData?.cover_image
          }
          alt=""
          style={{
            width: "100%",
            border: "5px solid black",
          }}
        />
        </div>
        

        <i
          onClick={handleClick}
          class="fa-solid fa-pen-to-square fa-2xl"
          style={{
            color: Colors.WHITE,
            position: "fixed",
            zIndex: 1,
            right: "1%",
            top: "15%",
            cursor: "pointer",
          }}
        />

        <input
          type="file"
          ref={hiddenFileInput}
          style={{ display: "none" }}
          onChange={handleChange}
        />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-12 mb-4 text-center">
            <img
              src={
                userData?.image != ""
                  ? BASE_URL_IMAGE + userData?.image
                  : userData.gender === "0"
                  ? Images.MaleDummyImage
                  : Images.FemaleDummyImage
              }
              style={{
                marginTop: "-5vh",
                width: "70%",
                maxWidth: "300px",
                height: "auto",
                borderRadius: 50 / 2,
                border: "5px solid white",
              }}
            />

            <i
              onClick={onButtonClickSelectImage}
              class="fa-solid fa-pen-to-square fa-2xl"
              style={{
                color: Colors.BLACK,
                // position: "fixed",
                // zIndex: 1,
                // right: "1%",
                // top: "15%",
                cursor: "pointer",
              }}
            />

            <input
              type="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={handleChangeimage}
            />
          </div>
          <div className="col-lg-8 col-md-12 profile-box">
            <h5>Name</h5>
            <p style={{ fontWeight: "200" }}>{userData?.name}</p>
            {userData?.mobile && (
              <>
                <h5>Phone Number</h5>
                <p style={{ fontWeight: "200" }}>{userData?.mobile}</p>
              </>
            )}
            {userData?.email && (
              <>
                <h5>Email</h5>
                <p style={{ fontWeight: "200" }}>{userData?.email}</p>
              </>
            )}

            <a
              className="link-normal"
              href="https://play.google.com/store/apps/details?id=com.aino.studycaller&pli=1"
            >
              For more detail and if you want to edit any detail login to &nbsp;
              <a href="https://play.google.com/store/apps/details?id=com.aino.studycaller&pli=1">
                Studycaller App
              </a>
            </a>
            <div className="mt-2">
              <Button
                onClick={() => {
                  localStorage.clear();
                  dispatch(logout());
                  dispatch(storecartEmpty());
                  navigate(ROUTENAME.HomeScreen);
                }}
              >
                Log Out
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileScreen;
