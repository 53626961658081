import React, { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { ADDProductInCart } from "../../pages/cart/index";
import { addstoreCartitem } from "../../redux/reducer/cartReducer";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Colors, ROUTENAME } from "../../helper/helper";
import { Button, Row } from "react-bootstrap";

const ProductBox = ({
  item,
  index,
  id,
  name,
  image,
  star,
  reviews,
  final_price,
  price,
  discountPercentage,
  subProductId,
  url,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storecartData = useSelector((state) =>
    state.cartReducer.storecartData.find((itemCart) => {
      return subProductId == itemCart.product;
    })
  );
  const AddTocartHandler = async () => {
    try {
      const Result = await ADDProductInCart(subProductId);
      console.log(Result);
      if (Result.status == true) {
        dispatch(addstoreCartitem(Result.data[0]));
      } else if (Result.status == false && Result.user_id == undefined) {
        Swal.fire({
          title: "Try to login first",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(ROUTENAME.LoginSCreen);
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div
      className="col-lg-3 col-md-4 col-sm-6"
      style={{
        textDecorationLine: "none",
        color: "ButtonText",
        // boxShadow: "0px 0px 5px 0px",
        // marginRight: 15,
        // marginBottom: 10,
        // backgroundColor: "#f5f5f5",
      }}
    >
      <Link
        to={
          "/product-detail/" +
          url +
          // name
          //   .replace(/[^a-zA-Z1-9 ]/g, "")
          //   .split(" ")
          //   .join("-")
          //   .toLowerCase()
          "/" +
          id
        }
      >
        <img className="product-img" src={image} alt="" />
      </Link>
      <div className="product-wedgits">
        <p>{name}</p>

        <div className="d-flex">
          <p style={{}}>₹ {final_price}</p>
          {price != final_price && (
            <p
              style={{
                textDecorationLine: "line-through",
              }}
            >
              ₹ {price}
            </p>
          )}
          {discountPercentage != 0 && (
            <div
              style={{
                backgroundColor: "red",
              }}
            >
              <p style={{ color: "#fff" }}>% {discountPercentage}</p>
            </div>
          )}
        </div>

        <Button
          // className="btn btn-submit"
          style={{
            backgroundColor: Colors.DARK_YELLOW,
            width: "100%",
            height: 40,
            borderWidth: 0,
            color: Colors.BLACK,
          }}
          onClick={() =>
            storecartData == undefined
              ? AddTocartHandler()
              : navigate(ROUTENAME.CartScreen)
          }
        >
          {storecartData == undefined ? "ADD TO CART" : "GO TO CART"}
        </Button>
      </div>
    </div>
  );
};

export default ProductBox;
