// export const RAZERPAY_API_KEY = "rzp_test_cwuSLU236kC6OJ";
// export const RAZERPAY_SCERET_KEY = "gwsg6Zs1ozxOeF7WBMg3NnLk";
export const RAZERPAY_API_KEY = "rzp_live_RX3HNm41BBPe00";
export const RAZERPAY_SCERET_KEY = "cY8aq6BPPGvexm1biPOkiqCR";
export const isLocal = false;

const BASE_URL_LOCAL = "https://studycaller.com/api-2/"; //LOCAL SERVER
const BASE_URL_LIVE = "https://studycaller.com/api-3/"; // LIVE SERVER
// const BASE_URL_LOCAL = "/api-2/"; //LOCAL SERVER
// const BASE_URL_LIVE = "/api-3/"; // LIVE SERVER
export const BASE_URL = isLocal ? BASE_URL_LOCAL : BASE_URL_LIVE;
export const BASE_URL_IMAGE = "https://studycaller.com/";

export const ApiEndPoint = {
  //user login
  login: BASE_URL + "login.php",
  loginverify: BASE_URL + "login-verify.php",
  signup: BASE_URL + "signup.php",
  GOOGLE_SIGN_UP: BASE_URL + "GoogleSignUp.php",
  statelist: BASE_URL + "stateList.php",
  updateprofileimg: BASE_URL + "edit-profile-pic.php",
  updareprofilecoverimg: BASE_URL + "edit-cover-pic.php",

  //store end points
  STORE_HOME_BANNER_API: BASE_URL + "get-store-banner.php",
  STORE_FEATURED_PRODUCT_API: BASE_URL + "featured-products.php",
  STORE_CATEGORY_API: BASE_URL + "store-category-list.php",
  STORE_SUB_CATEGORY_API: BASE_URL + "store-sub-category.php",
  STORE_PRODUCT_LIST_API: BASE_URL + "store-product-list.php",
  STORE_FILTERED_PRODUCT_LIST_API: BASE_URL + "store-filterd-product2.php",
  STORE_PRODUCT_DETAIL_API: BASE_URL + "product-detail.php",
  ADD_PRODUCT_REVIEW_API: BASE_URL + "add-product-review.php",

  ADD_STORE_CART_API: BASE_URL + "store-cart.php",
  STORE_CART_DATA_API: BASE_URL + "store-cart-data.php",
  USER_ORDERED_STORE_API: BASE_URL + "user-store-order.php",
  USER_ORDERED_STORE_DATA_API: BASE_URL + "user-store-order-data.php",
  FAILED_USER_ORDERED_STORE_DATA_API: BASE_URL + "store-failed-order.php",
  DELETE_STORE_ITEM_API: BASE_URL + "delete-store-cart.php",

  //Address
  ADD_STUDENT_ADDRESS_API: BASE_URL + "add-student-address.php",
  STUDENT_ADDRESS_DATA_API: BASE_URL + "student-address-data.php",
  DELETE_STUDENT_ADDRESS_API: BASE_URL + "delete-student-address.php",

  //razer paypayment
  CREATE_ORDERID_RAZERPAY_API: BASE_URL + "razorpay-order.php",
};

export const Images = {
  UserBackgroundcoverimage: BASE_URL_IMAGE + "images/web-timeline.jpeg",
  MaleDummyImage: BASE_URL_IMAGE + "images/male-user.png",
  FemaleDummyImage: BASE_URL_IMAGE + "images/female-user.png",
};

export const METHODS = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const HEADERS = {
  // Accept: "applicaiton/json",
  "Content-Type": "multipart/form-data",
};
export const ROUTENAME = {
  HomeScreen: "/",
  LoginSCreen: "/login",
  CartScreen: "/cart",
  ProfileScreen: "/profile",
};

export const Colors = {
  // PrimaryColor: '#007AEB',
  PrimaryColor: "#406DCF",
  PrimaryColor_2: "#406DCF",
  PrimaryColorLight: "#E9F7FF",
  Yellow_Light: "#FDF1C5",
  Dark_Yellow_Light: "#E9C135",
  Dark_Purple_Light: "#3F65DE",
  Purple_Light: "#E0E7FD",
  ScreenBGColor: "#FAFAFA",
  CardBG: "#FFFFFF",
  CardBorderColor: "#ECECEC",
  WarningColor: "#FB7A7A",
  WarningBGColor: "#F6D8D8",
  SuccessColor: "#5EEBA7",
  SuccessBGColor: "#E7FFE8",
  TextPrimary: "#1F1F39",
  TextSecondary: "#646464",
  DarkGrey: "#646464",
  DarkGrey_2: "#c1c1c1",
  OrganeTrackColor: "#F4A015",
  OrganeLightTrackColor: "#FDF2E0",
  BGColor: "#FAFAFA",
  BorderColor_2: "#E2E2E2",
  BorderColor_3: "#AFAFAF",

  WHITE: "#ffffff",
  BLACK: "#000000",
  TextGreen: "#146017",
  TextGreen_2: "#27a885",
  DashedPurple: "#9747FF",
  FieldBorderColor: "#C0C0C0",
  TextGrey: "#858597",
  TextGrey_2: "#7D7D7D",
  RED_COLOR: "#CE2929",
  OrangeLight: "#FFF8ED",
  OrangeDark: "#E4931A",
  BUTTONBG_COLOR: "#FCF9FC",
  LIGHT_GREEN: "#E0FBEF",
  DARK_GREEN: "#0BA95D",
  STAR_COLOR: "#EDB116",
  LIGHT_GREY: "#efefef",
  DARK_YELLOW: "#fed813",
};

export const INRupee = (price) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(price);
};
export const htmlSpecialCharsDecode = (text) => {
  if (text == "" || text == undefined || text == null) {
    return "";
  } else {
    const map = {
      "&amp;": "&",
      "&#038;": "&",
      "&nbsp;": " ",
      "&lt;": "<",
      "&gt;": ">",
      "&quot;": '"',
      "&#039;": "'",
      "&#8217;": "’",
      "&#8216;": "‘",
      "&#8211;": "–",
      "&#8212;": "—",
      "&#8230;": "…",
      "&#8221;": "”",
    };

    return text.replace(/\&[\w\d\#]{2,5}\;/g, (m) => map[m] ?? m);
  }
};
