import { ApiEndPoint } from "../../helper/helper";
import { METHODS } from "../../helper/helper";
import { fetchData } from "../../helper/ApiCall";

/////////////////////////////////////
// // // // // // // // // // // // // // // // // // // // // // // // // // // //
const falseResult = { status: false, user_id: undefined };

export const ADDProductInCart = async (productId) => {
  try {
    const user_id = localStorage.getItem("user_id");
    console.log("scsdcd", user_id);
    if (user_id == null) return falseResult;
    var formdata = new FormData();
    formdata.append("studentId", user_id);
    formdata.append("product", productId);

    const Result = await fetchData(
      ApiEndPoint.ADD_STORE_CART_API,

      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
export const RemoveProductInCart = async (productId) => {
  try {
    const user_id = localStorage.getItem("user_id");

    var formdata = new FormData();
    formdata.append("studentId", user_id);
    formdata.append("productId", productId);
    console.log(formdata);
    const Result = await fetchData(
      ApiEndPoint.DELETE_STORE_ITEM_API,

      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
export const GetStoreCartData = async () => {
  try {
    const user_id = localStorage.getItem("user_id");

    var formdata = new FormData();
    formdata.append("studentId", user_id);

    const Result = await fetchData(
      ApiEndPoint.STORE_CART_DATA_API,

      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
export const OrderStoreCartData = async (
  orderId,
  paymentId,
  subTotal,
  discount,
  total,
  json
) => {
  try {
    const user_id = localStorage.getItem("user_id");

    var formdata = new FormData();
    formdata.append("studentId", user_id);
    formdata.append("orderId", orderId);
    formdata.append("paymentId", paymentId);
    formdata.append("subTotal", subTotal);
    formdata.append("discount", discount);
    formdata.append("total", total);
    formdata.append("json", JSON.stringify(json));
    console.log(formdata);
    const Result = await fetchData(
      ApiEndPoint.USER_ORDERED_STORE_API,

      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
export const FailedOrderStoreCartData = async (products, total, json) => {
  try {
    const user_id = localStorage.getItem("user_id");

    var formdata = new FormData();
    formdata.append("studentId", user_id);
    formdata.append("products", products.toString());
    formdata.append("total", total);
    formdata.append("json", JSON.stringify(json));

    const Result = await fetchData(
      ApiEndPoint.FAILED_USER_ORDERED_STORE_DATA_API,

      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
export const GetOrderStoreData = async () => {
  try {
    const user_id = localStorage.getItem("user_id");

    var formdata = new FormData();
    formdata.append("studentId", user_id);

    const Result = await fetchData(
      ApiEndPoint.USER_ORDERED_STORE_DATA_API,

      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};

//////////student address///////////////////////

export const ADDStudentAddress = async (data, state) => {
  try {
    const user_id = localStorage.getItem("user_id");

    var formdata = new FormData();
    formdata.append("studentId", user_id);
    formdata.append("type", data.type);
    formdata.append("pincode", data.pincode);
    formdata.append("address_one", data.address_one);
    formdata.append("address_two", data.address_two);
    formdata.append("landmark", data.landmark);
    formdata.append("city", data.city);
    formdata.append("state", state);
    if (data.addressId != "") {
      formdata.append("addressId", data.addressId);
    }
    formdata.append("phone", data.mobile);
    formdata.append("email", data.email);
    console.log(formdata);
    const Result = await fetchData(
      ApiEndPoint.ADD_STUDENT_ADDRESS_API,
      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
export const RemoveStudentAddress = async (addressId) => {
  try {
    const user_id = localStorage.getItem("user_id");

    var formdata = new FormData();
    formdata.append("studentId", user_id);
    formdata.append("addressId", addressId);
    console.log(formdata);
    const Result = await fetchData(
      ApiEndPoint.DELETE_STUDENT_ADDRESS_API,

      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
export const GetStudentAddressData = async () => {
  try {
    const user_id = localStorage.getItem("user_id");

    var formdata = new FormData();
    formdata.append("studentId", user_id);

    const Result = await fetchData(
      ApiEndPoint.STUDENT_ADDRESS_DATA_API,

      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
export const GetStateListData = async () => {
  try {
    const Result = await fetchData(ApiEndPoint.statelist, METHODS.GET);

    return Result;
  } catch (error) {
    throw error;
  }
};
//payment
export const GetOrderIdForRzerpay = async (amount) => {
  try {
    var formdata = new FormData();
    formdata.append("total", amount);

    const Result = await fetchData(
      ApiEndPoint.CREATE_ORDERID_RAZERPAY_API,

      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
