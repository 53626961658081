import { HEADERS, METHODS } from "./helper";

export const fetchData = (URL, method, body) => {
  // console.log({
  //   method: method,
  //   // headers: HEADERS,
  //   mode: "no-cors",
  //   timeout: 10000 /* 10 seconds */,
  //   body: body,
  // });
  // for (var pair of body.entries()) {
  //   console.log(pair[0] + ", " + pair[1]);
  // }
  return fetch(URL, {
    method: method,
    // headers: HEADERS,
    // mode: "no-cors",
    timeout: 10000 /* 10 seconds */,
    body: method === METHODS.GET ? null : body,
    // signal
  })
    .then((response) => {
      return response.json();
    })
    .then(async (result) => {
      // console.log('Result: ' + JSON.stringify(result));

      return result;
    })
    .catch(function (error) {
      console.log("errror=======" + error);
      throw error;
    });
};
