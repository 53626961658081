import React, { useEffect, useState } from "react";
import ProductBox from "../../container/ProductBox/ProductBox";

import {
  GetStoreProductList,
  GetStoreFilteredProductList,
} from "../home/index";
import { useParams } from "react-router-dom";
import { Col, Form, Row } from "react-bootstrap";
import {
  BASE_URL_IMAGE,
  Colors,
  htmlSpecialCharsDecode,
} from "../../helper/helper";

const ProductScreen = () => {
  const { name, categoryId } = useParams();
  const [show, setShow] = useState();
  const [data, setdata] = useState();
  const [main_Data, setmain_Data] = useState();
  const [specification, setspecification] = useState();
  const [selectedspecification, setselectedspecification] = useState([]);
  const [filtermodalshow, setfiltermodalshow] = useState(false);
  const [modalshow, setmodalshow] = useState(false);
  const [selectedsortby, setselectedsortby] = useState();
  const [filtershow, setfiltershow] = useState([]);

  const dataHandler = async () => {
    try {
      const Result = await GetStoreProductList(categoryId);
      console.log(Result);
      if (Result.status == true) {
        setmain_Data(Result.data);
        setdata(Result.data);
        setspecification(Result.specification);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    // dataHandler();
  }, []);
  useEffect(() => {
    if (selectedspecification.length > 0) {
      filterproductlistHandler();
    } else {
      dataHandler();
    }

    return () => {
      // Cancel the request when the component unmounts
      // abortController.abort();
    };
  }, [selectedspecification]);

  const filterproductlistHandler = async () => {
    try {
      const newArray = [];

      selectedspecification.map((item, index) => {
        if (index == 0) {
          newArray.push(...item.value.map((itemVal) => itemVal.id));
        } else {
          newArray[newArray.length - 1] = `${newArray[newArray.length - 1]}/${
            item.value[0].id
          }`;
          item.value.map((itemVal, indexVal) => {
            if (indexVal != 0) {
              newArray.push(itemVal.id);
            }
          });
        }
      });
      console.log(newArray);
      const Result = await GetStoreFilteredProductList(newArray);

      if (Result.status == true) {
        setmain_Data(Result.data);
        setdata(Result.data);
      } else {
        setdata();
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const SelectIdHandler = async (itemName, itemVal, findId) => {
    const newArray = [...selectedspecification];
    var index = -1;
    newArray.map((itemFind, i) => {
      if (itemFind.name == itemName) {
        index = i;

        return;
      }
    });
    if (index == -1) {
      const addData = {
        name: itemName,
        value: [itemVal],
      };
      setselectedspecification([...newArray, addData]);
    } else {
      if (findId == "add") {
        newArray[index].value.push(itemVal);
        setselectedspecification([...newArray]);
      } else {
        if (newArray[index].value.length == 1) {
          const valueOuter = newArray.filter((itemFilter) => {
            return itemFilter.name != itemName;
          });
          setselectedspecification(valueOuter);
        } else {
          const valueInner = newArray[index].value.filter((itemFilter) => {
            return itemFilter.id != itemVal.id;
          });
          newArray[index].value = valueInner;
          setselectedspecification([...newArray]);
        }
      }
    }
  };

  const sortbyFilterHandler = (value) => {
    console.log(value);

    setdata();
    switch (value) {
      case "Best Selling":
        const bestselling = [...main_Data].filter((item) => {
          return item.bestselling == "1";
        });
        if (bestselling.length == 0) {
          setdata();
        } else {
          setdata(bestselling);
        }
        break;
      case "Alphabetically, A-Z":
        const atoz = [...main_Data].sort((a, b) => {
          return a?.name.localeCompare(b?.name);
        });

        setdata(atoz);
        break;
      case "Alphabetically, Z-A":
        const ztoa = [...main_Data].sort((a, b) => {
          return b?.name.localeCompare(a?.name);
        });

        setdata(ztoa);
        break;
      case "Price, low to high":
        const lowtohigh = [...main_Data].sort((a, b) => {
          return (
            Number(a?.sub_product?.final_price) -
            Number(b?.sub_product?.final_price)
          );
        });

        setdata(lowtohigh);
        break;
      case "Price, high to low":
        const hightolow = [...main_Data].sort((a, b) => {
          return (
            Number(b?.sub_product?.final_price) -
            Number(a?.sub_product?.final_price)
          );
        });

        setdata(hightolow);
        break;

      case "Date, new to old":
        const datenewtoold = [...main_Data].sort((a, b) => {
          return new Date(b?.created_at) - new Date(a?.created_at);
        });

        setdata(datenewtoold);
        break;
      case "Date, old to new":
        const dateoldtonew = [...main_Data].sort((a, b) => {
          return new Date(a?.created_at) - new Date(b?.created_at);
        });

        setdata(dateoldtonew);
        break;
      default:
        setdata(main_Data);

        break;
    }
  };
  const SortByFilter = () => {
    return (
      <Form.Select
        style={{ width: "100%", maxWidth: "200px", alignSelf: "end" }}
        value={selectedsortby}
        onChange={(e) => {
          setselectedsortby(e.target.value);
          sortbyFilterHandler(e.target.value);
        }}
      >
        {/* // <select
      //   value={selectedsortby}
      //   onChange={(e) => {
      //     setselectedsortby(e.target.value);
      //     sortbyFilterHandler(e.target.value);
      //   }}
      // > */}
        <option value={"Select"}>Select</option>
        <option value={"Best Selling"}>Best Selling</option>
        <option value={"Alphabetically, A-Z"}>Alphabetically, A-Z</option>
        <option value={"Alphabetically, Z-A"}>Alphabetically, Z-A</option>

        <option value={"Price, low to high"}>Price, low to high</option>
        <option value={"Price, high to low"}>Price, high to low</option>
        <option value={"Date, new to old"}>Date, new to old</option>
        <option value={"Date, old to new"}>Date, old to new</option>
        {/* </select> */}
      </Form.Select>
    );
  };
  return (
    <div
      className="main"
      style={{
        padding: "2vw",
      }}
    >
      <div className="pt-5">
        <div
          className="row"
          style={{
            justifyContent: "space-between",
          }}
        >
          <div className="col-6">
            <button className="btn" onClick={() => setShow(!show)}>
              Filters{" "}
              <svg
                width={20}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z" />
              </svg>
            </button>
          </div>
          <div className="col-6 d-flex justify-content-end">
            <div>
              <span>
                <b>SORT BY</b>
              </span>
              <SortByFilter style={{ alignSelf: "end" }} />
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div
            className="col-lg-2 col-md-12"
            style={{
              display: show ? "block" : "none",
              zIndex: 99,
              background: "white",
            }}
          >
            <div className="row m-0">
              <div className="col-8 text-center m-0"> Filters </div>
              <div className="col-2 text-end m-0">
                <button
                  className="btn btn-success"
                  onClick={() => setShow(!show)}
                >
                  Apply
                </button>
              </div>
            </div>

            {specification != undefined &&
              specification.map((item, index) => {
                const findFiltershow = filtershow.find((itemVal) => {
                  return itemVal == item.id;
                });
                return (
                  <div
                    className="item"
                    style={{
                      marginBottom: 10,
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: `0.5px groove ${Colors.BorderColor_3}`,
                        marginBottom: 15,
                      }}
                    >
                      <p style={{ margin: 0, marginBottom: 5 }}>
                        <b>{item.name}</b>
                      </p>
                      <i
                        className={`fa-solid fa-chevron-${
                          findFiltershow == undefined ? "up" : "down"
                        }`}
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (findFiltershow == undefined) {
                            setfiltershow((current) => [...current, item.id]);
                          } else {
                            const newArray = filtershow.filter((itemval) => {
                              return item.id != itemval;
                            });
                            setfiltershow(newArray);
                          }
                        }}
                      ></i>
                    </div>
                    {findFiltershow == undefined && (
                      <>
                        {item?.value != undefined &&
                          item.value.map((itemVal, indexVal) => {
                            const findId = selectedspecification.find(
                              (itemFind) => {
                                return itemFind.value.some(
                                  (itemVal2) => itemVal2.id == itemVal.id
                                );
                              }
                            );

                            return (
                              <Col
                                key={indexVal}
                                style={{ marginLeft: 15, display: "flex" }}
                              >
                                <input
                                  type="checkbox"
                                  checked={findId == undefined ? false : true}
                                  onChange={(e) => {
                                    SelectIdHandler(
                                      item.name,
                                      itemVal,
                                      findId == undefined ? "add" : "sub"
                                    );
                                  }}
                                />
                                &nbsp;&nbsp;
                                <p
                                  className=""
                                  style={{
                                    color: Colors.TextSecondary,
                                    margin: 0,
                                  }}
                                >
                                  {htmlSpecialCharsDecode(itemVal.value)}
                                </p>
                              </Col>
                            );
                          })}
                      </>
                    )}
                  </div>
                );
              })}
          </div>
          <Col>
            <Row>
              {data != undefined ? (
                data.map((item, index) => {
                  return (
                    <ProductBox
                      key={index}
                      item={item}
                      index={index}
                      name={item.name}
                      image={BASE_URL_IMAGE + item.thumbnail}
                      star={item.star}
                      reviews={item.reviews}
                      final_price={item?.sub_product?.final_price}
                      price={item?.sub_product?.price}
                      discountPercentage={
                        item?.sub_product?.discount_percentage
                      }
                      url={item.url}
                      id={item.id}
                      subProductId={item?.sub_product?.id}
                    />
                  );
                })
              ) : (
                <div>
                  <p>No Product Found</p>
                </div>
              )}
            </Row>
          </Col>
        </div>
      </div>
    </div>
  );
};

export default ProductScreen;
