import React from "react";

const Footer = () => {
  return (
    <div className="text-center text-lg-start text-muted footer p-4">
      <section className="">
        <div className=" container text-center text-md-start">
          <div className=" row ">
            <div className="col-lg-4 col-md-12 mb-4">
              <img
                className="footer-img"
                src="/assets/images/logo-nav.png"
                alt=""
              />
              <p className="text-light">Powered By </p>
              <img
                className="footer-img bg-light"
                src="https://studycaller.com/assets/images/logo/ca_logo.png"
                alt=""
              />
            </div>

            <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-light">
                Company
              </h6>
              <p>
                <a
                  href="https://studycaller.com/about-us.php"
                  className="link-normal text-light"
                >
                  About Us
                </a>
              </p>
              <p>
                <a
                  href="https://studycaller.com/contact.php"
                  className="link-normal text-light"
                >
                  Contact Us
                </a>
              </p>
              <p>
                <a
                  href="https://studycaller.com/refund-and-cancellation.php"
                  className="link-normal text-light"
                >
                  Refund And Cancellation
                </a>
              </p>
            </div>

            <div className="col-lg-2 col-md-4 col-sm-6 mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-light">Legal</h6>
              <p>
                <a
                  href="https://studycaller.com/term-and-conditions.php"
                  className="link-normal text-light"
                >
                  Terms & Conditions
                </a>
              </p>
              <p>
                <a
                  href="https://studycaller.com/privacy-policy.php"
                  className="link-normal text-light"
                >
                  Privacy Policy
                </a>
              </p>
            </div>

            <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
              <h6 className="text-uppercase fw-bold mb-4 text-light">
                Contact
              </h6>
              <p className="text-light">
                3rd Floor, The Corenthum, India Accelerator, Sector 62, Noida,
                Uttar Pradesh 201301
              </p>
              <p className="text-light">+91 9811922352</p>
              <p className="text-light">info@studycaller.com</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
