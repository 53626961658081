import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { GetStoreSubCategoryList } from "./index";
import { BASE_URL_IMAGE } from "../../helper/helper";
const CategoryScreen = () => {
  const { name, categoryId } = useParams();
  const [category_data, setcategory_data] = useState();

  useEffect(() => {
    categoryHandler();
  }, []);

  const categoryHandler = async () => {
    try {
      const Result = await GetStoreSubCategoryList(categoryId);

      if (Result.status == true) {
        setcategory_data(Result.data);
      } else {
        setcategory_data();
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div className="main">
      <div className="container">
        <h1 className="mb-5 mt-5">Product Categories: {name}</h1>
        <div className="d-flex mb-2 pt-4">
          {category_data != undefined &&
            category_data.map((item, index) => {
              return (
                <div
                  style={{
                    marginLeft: index == 0 ? 0 : 30,
                    display: "flex",
                  }}
                >
                  <Link
                    className="link-normal text-center"
                    key={index}
                    // to={item.name != "Test Series" && `/2/2`}

                    to={`/${item.url}/${item.id}`}
                  >
                    <img
                      src={BASE_URL_IMAGE + item.image}
                      alt=""
                      style={{
                        width: 300,
                        height: 300,
                        marginBottom: 20,
                      }}
                    />
                    <h4>{item.name}</h4>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default CategoryScreen;
