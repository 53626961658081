import React, { useEffect, useState } from "react";
import "./LoginScreen.css";
import Form from "react-bootstrap/Form";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import {
  GoogleSignupHandler,
  LoginHandler,
  LoginVerificationHandler,
  MobileVerificationHandler,
  SignupHandler,
} from "./index";
import { useNavigate } from "react-router-dom";
import { ROUTENAME } from "../../helper/helper";
import Swal from "sweetalert2";
import { userdataUpdate } from "../../redux/reducer/userReducer";
import { useSelector, useDispatch } from "react-redux";

const LoginScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userReducer.userData);

  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);
  const [singupform, setsingupform] = useState(false);
  const [otpForm, setotpForm] = useState(false);
  const [otp, setotp] = useState();
  const [type, settype] = useState("login");
  const [mobile, setmobile] = useState();
  const [username, setusername] = useState();
  const [name, setname] = useState();
  const [phone, setphone] = useState();
  const [profileimage, setprofileimage] = useState();
  const [gender, setgender] = useState();

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setProfile(res.data);
          GoogleSignHandler(res.data);
        })
        .catch((err) => console.log(err));
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const GoogleSignHandler = async (data) => {
    try {
      const result = await GoogleSignupHandler(data);

      if (result.status == true) {
        localStorage.setItem("user_id", result.data.id);
        localStorage.setItem("status", "true");
        dispatch(userdataUpdate(result.data));
        navigate(ROUTENAME.HomeScreen);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const logOut = () => {
    googleLogout();
    setProfile(null);
  };
  const Validation = () => {
    if (name == undefined) {
      return "Enter phone number";
    } else if (phone == undefined) {
      return "Select gender";
    } else if (gender == undefined) {
      return "Select gender";
    } else {
      return true;
    }
  };
  const handlerSignUp = async () => {
    try {
      const validate = Validation();
      if (validate == true) {
        const Result = await SignupHandler(
          username,
          name.trim(),
          phone,
          gender,
          profile
        );
        if (Result.status == true) {
          setotpForm(true);
        } else if (Result.status == false) {
          Swal.fire({
            title: "error",
            text: Result.message,
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
          });
        }
      } else {
        Swal.fire({
          title: "error",
          text: validate,
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const handler = async () => {
    if (mobile.toString().length == 10) {
      console.log(mobile);

      try {
        const Result = await LoginHandler(mobile);

        if (Result.status == true) {
          setotpForm(true);
        } else if (
          Result.status == false &&
          Result.message == "Mobile No. not registered"
        ) {
          settype("signup");
          setsingupform(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      alert("Enter valid number");
    }
  };

  const VerificationHandler = async () => {
    try {
      if (type == "login") {
        var Result = await LoginVerificationHandler(mobile, otp, "", "web");
      } else {
        var Result = await MobileVerificationHandler(mobile, otp, "", "web");
      }

      if (Result.status == true) {
        localStorage.setItem("mobile", mobile);
        localStorage.setItem("user_id", Result.data.id);
        localStorage.setItem("status", "true");
        dispatch(userdataUpdate(Result.data));
        navigate(ROUTENAME.HomeScreen);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const resendHandler = async () => {
    try {
      const Result = await LoginHandler(mobile);
      if (Result.status == true) {
        // setVisible(false);
        // setMinutes();
        // setSeconds(59);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div>
      <div className="justify-content-center">
        <div className="login-form">
          <img className="mb-3" src="assets/images/logo.png" alt="" />
          {!otpForm ? (
            <>
              {!singupform ? (
                <>
                  <div className="btn-group">
                    <button className="btn" onClick={login}>
                      <img
                        className="logo"
                        src="assets/images/google-logo.png"
                        alt=""
                      />
                      <span>Sign in with Google</span>
                    </button>
                  </div>
                  <Form.Text>Login / Register</Form.Text>
                  <Form className="mt-2">
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="Enter Phone Number"
                        value={mobile}
                        onChange={(e) => {
                          setmobile(e.target.value);
                        }}
                      />
                      <Form.Text>
                        You will receive an SMS verification that may apply
                        message and data rates.
                      </Form.Text>
                    </Form.Group>
                  </Form>
                  <button
                    className="btn btn-submit "
                    type="btn"
                    onClick={handler}
                  >
                    Submit
                  </button>
                </>
              ) : (
                <>
                  <Form className="mt-2">
                    <Form.Group className="mb-3">
                      <Form.Control
                        value={name}
                        onChange={(e) => {
                          setname(e.target.value);
                        }}
                        placeholder="Enter Name"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        value={mobile}
                        // onChange={(e) => {
                        //   setmobile(e.target.value);
                        // }}
                        placeholder="Enter Phone Number"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Select
                        // value={gender}
                        onChange={(e) => {
                          setgender(e.target.value);
                        }}
                      >
                        <option>Select Gender</option>
                        <option value={"0"}>Male</option>
                        <option value={"1"}>Female</option>
                        <option value={"2"}>Other</option>
                      </Form.Select>
                    </Form.Group>
                  </Form>
                  <button
                    className="btn btn-submit "
                    type="btn"
                    onClick={handlerSignUp}
                  >
                    Continue
                  </button>
                </>
              )}
            </>
          ) : (
            <>
              <Form className="mt-2">
                <Form.Group className="mb-3">
                  <Form.Control
                    value={otp}
                    onChange={(e) => {
                      setotp(e.target.value);
                    }}
                    placeholder="Enter otp"
                  />
                </Form.Group>
              </Form>
              <button
                className="btn btn-submit "
                type="btn"
                onClick={VerificationHandler}
              >
                SUBMIT
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
