import logo from "./logo.svg";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

//start redux files
import { Provider } from "react-redux";
import { store, Persistor } from "./redux/store/store";
import { PersistGate } from "redux-persist/integration/react";
//end redux files
import LoginScreen from "./pages/login/LoginScreen";
import HomeScreen from "./pages/home/HomeScreen";

import CartScreen from "./pages/cart/CartScreen";
import ProductScreen from "./pages/product/ProductScreen";
import ProductDetailScreen from "./pages/product/ProductDetailScreen";
import NavBar from "./container/NavBar/NavBar";
import Footer from "./container/Footer/Footer";
import ProfileScreen from "./pages/profile/ProfileScreen";
import CategoryScreen from "./pages/category/CategoryScreen";
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={Persistor}>
        <Router>
          <NavBar />

          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/login" element={<LoginScreen />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route
              path="/category/:name/:categoryId"
              element={<CategoryScreen />}
            />

            <Route path="/:name/:categoryId" element={<ProductScreen />} />
            <Route path="/cart" element={<CartScreen />} />
            <Route
              path="/product-detail/:productName/:product_id"
              element={<ProductDetailScreen />}
            />
          </Routes>
          <Footer />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
