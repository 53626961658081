import { ApiEndPoint } from "../../helper/helper";
import { METHODS } from "../../helper/helper";
import { fetchData } from "../../helper/ApiCall";

/////////////////////////////////////

export const GetStoreBannerList = async () => {
  try {
    const Result = await fetchData(
      ApiEndPoint.STORE_HOME_BANNER_API,
      METHODS.GET
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
export const GetStoreFeaturedProductList = async () => {
  try {
    const Result = await fetchData(
      ApiEndPoint.STORE_FEATURED_PRODUCT_API,
      METHODS.GET
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
export const GetStoreCategoryList = async () => {
  try {
    const Result = await fetchData(ApiEndPoint.STORE_CATEGORY_API, METHODS.GET);

    return Result;
  } catch (error) {
    throw error;
  }
};
export const GetStoreSubCategoryList = async (category_id) => {
  try {
    var formdata = new FormData();
    formdata.append("category", category_id);

    const Result = await fetchData(
      ApiEndPoint.STORE_SUB_CATEGORY_API,

      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
export const GetStoreProductList = async (sub_category_id) => {
  try {
    var formdata = new FormData();

    formdata.append("sub_category", sub_category_id);

    const Result = await fetchData(
      ApiEndPoint.STORE_PRODUCT_LIST_API,
      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
export const GetStoreFilteredProductList = async (id) => {
  try {
    var formdata = new FormData();
    formdata.append("specification_value", id.toString());
    console.log(formdata);
    const Result = await fetchData(
      ApiEndPoint.STORE_FILTERED_PRODUCT_LIST_API,
      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
export const GetProductDetailById = async (product_id) => {
  try {
    var formdata = new FormData();
    formdata.append("product_id", product_id);

    const Result = await fetchData(
      ApiEndPoint.STORE_PRODUCT_DETAIL_API,

      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
export const ADDProductReview = async (productId, star, title, review) => {
  try {
    const user_id = localStorage.getItem("user_id");

    var formdata = new FormData();
    formdata.append("studentId", user_id);
    formdata.append("productId", productId);
    formdata.append("star", star);
    formdata.append("title", title);
    formdata.append("review", review);
    console.log(formdata);
    const Result = await fetchData(
      ApiEndPoint.ADD_PRODUCT_REVIEW_API,

      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
