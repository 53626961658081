import { ApiEndPoint } from "../../helper/helper";
import { METHODS } from "../../helper/helper";
import { fetchData } from "../../helper/ApiCall";

export const UpdateProfilePic = async (image) => {
  const user_id = localStorage.getItem("user_id");

  try {
    var formdata = new FormData();
    formdata.append("studentId", user_id);
    formdata.append("image", image);

    const Result = await fetchData(
      ApiEndPoint.updateprofileimg,
      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    console.log("error", error);
  }
};
export const UpdateProfileCoverPic = async (image) => {
  const user_id = localStorage.getItem("user_id");

  try {
    var formdata = new FormData();
    formdata.append("studentId", user_id);
    formdata.append("cover_image", image);

    const Result = await fetchData(
      ApiEndPoint.updareprofilecoverimg,
      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    console.log("error", error);
  }
};
