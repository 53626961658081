import { ApiEndPoint } from "../../helper/helper";
import { METHODS } from "../../helper/helper";
import { fetchData } from "../../helper/ApiCall";

/////////////////////////////////////
const falseResult = { status: false, user_id: undefined };

export const GetProductDetailById = async (product_id) => {
  try {
    var formdata = new FormData();
    formdata.append("product_id", product_id);

    const Result = await fetchData(
      ApiEndPoint.STORE_PRODUCT_DETAIL_API,

      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
export const ADDProductReview = async (productId, star, title, review) => {
  try {
    const user_id = localStorage.getItem("user_id");
    if (user_id == null) return falseResult;

    var formdata = new FormData();
    formdata.append("studentId", user_id);
    formdata.append("productId", productId);
    formdata.append("star", star);
    formdata.append("title", title);
    formdata.append("review", review);
    console.log(formdata);
    const Result = await fetchData(
      ApiEndPoint.ADD_PRODUCT_REVIEW_API,

      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};
