import { ApiEndPoint } from "../../helper/helper";
import { METHODS } from "../../helper/helper";
import { fetchData } from "../../helper/ApiCall";

/////////////////////////////////////


export const GetStoreSubCategoryList = async (category_id) => {
  try {
    var formdata = new FormData();
    formdata.append("category", category_id);

    const Result = await fetchData(
      ApiEndPoint.STORE_SUB_CATEGORY_API,
      METHODS.POST,
      formdata
    );

    return Result;
  } catch (error) {
    throw error;
  }
};