import React, { useEffect, useState } from "react";

import "./HomeScreen.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ProductBox from "../../container/ProductBox/ProductBox";

import {
  GetStoreFeaturedProductList,
  GetStoreBannerList,
  GetStoreCategoryList,
} from "./index";
import { Link } from "react-router-dom";
import { BASE_URL_IMAGE, GlobalStyle, Colors } from "../../helper/helper";
import { addallstoreCartdata } from "../../redux/reducer/cartReducer";
import { useSelector, useDispatch } from "react-redux";
import { GetStoreCartData } from "../cart";
import { Col, Row } from "react-bootstrap";
const options = {
  loop: true,
  center: true,
  items: 3,
  margin: 40,
  autoplay: true,
  dots: true,
  autoplayTimeout: 8500,
  smartSpeed: 450,
  nav: false,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 3,
    },
  },
};
const HomeScreen = () => {
  const dispatch = useDispatch();
  const { storecartData } = useSelector((state) => state.cartReducer);
  const [BannerData, setBannerData] = useState();
  const [category_data, setcategory_data] = useState();
  const [fetauredproduct_data, setfetauredproduct_data] = useState();
  useEffect(() => {
    dataHandler();
  }, []);

  const dataHandler = async () => {
    try {
      const Result = await Promise.all([
        GetStoreBannerList(),
        GetStoreCategoryList(),
        GetStoreFeaturedProductList(),
        storecartData.length == 0 && GetStoreCartData(),
      ]);

      if (Result[0].status == true) {
        setBannerData(Result[0].data);
      }
      if (Result[1].status == true) {
        setcategory_data(Result[1].data);
      }
      if (Result[2].status == true) {
        setfetauredproduct_data(Result[2].data);
      }
      if (Result[3].status == true && storecartData.length == 0) {
        console.log(Result[3]);
        dispatch(addallstoreCartdata(Result[3].data));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <div className="main">
        <div className="container">
          <h1 className="mb-2">Product Categories</h1>

          <div className="d-flex mb-2 pt-4">
            {category_data != undefined &&
              category_data.map((item, index) => {
                return (
                  item.name != "Test Series" &&
                  item.name != "Audio Notes" && (
                    <div
                      style={{
                        marginLeft: index == 0 ? 0 : 20,
                        display: "flex",
                      }}
                    >
                      <Link
                        className="d-flex link-normal text-center"
                        key={index}
                        // to={item.name != "Test Series" && `/2/2`}
                        to={
                          item.StoreSub.length == 1
                            ? `/${item.StoreSub[0].url}/${item.StoreSub[0].id}`
                            : `/category/${item.name}/${item.id}`
                        }
                        // to={
                        //   item.name != "Test Series" &&
                        //   `/${item.StoreSub[0].url}/${item.StoreSub[0].id}`
                        // }
                      >
                        <img
                          className="category-img"
                          src={BASE_URL_IMAGE + item.image}
                          alt=""
                        />
                      </Link>
                    </div>
                  )
                );
              })}
          </div>
          {BannerData != undefined && (
            <>
              <h1 className="mb-2">Offers</h1>
              <OwlCarousel className="owl-theme mb-2" {...options}>
                {/* <Row> */}
                {BannerData != undefined &&
                  BannerData.map((item, index) => {
                    return (
                      <div className="item" key={index}>
                        <img
                          className="carousel-img"
                          src={BASE_URL_IMAGE + item.image}
                        />
                      </div>
                    );
                  })}
                {/* </Row> */}
              </OwlCarousel>
            </>
          )}
          <h1 className="mb-2 text-center mt-2 heading-one">Recommended</h1>
          <div className="row ">
            {fetauredproduct_data != undefined &&
              fetauredproduct_data.map((item, index) => {
                return (
                  <ProductBox
                    key={index}
                    item={item}
                    index={index}
                    name={item?.product?.name}
                    image={BASE_URL_IMAGE + item?.product?.thumbnail}
                    star={item.star}
                    reviews={item.reviews}
                    final_price={item.final_price}
                    price={item.price}
                    discountPercentage={item.discount_percentage}
                    id={item?.product?.id}
                    subProductId={item?.id}
                    url={item?.product?.url}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeScreen;
