import React, { useEffect, useState } from "react";
import { GetProductDetailById, ADDProductReview } from "./index";
import { ADDProductInCart } from "../cart/index";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { addstoreCartitem } from "../../redux/reducer/cartReducer";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import {
  BASE_URL_IMAGE,
  Colors,
  INRupee,
  ROUTENAME,
  htmlSpecialCharsDecode,
} from "../../helper/helper";
import StarContainer from "../../container/StarContainer";
import ReviewsContainer from "../../container/ReviewsBox/ReviewsContainer";
import Swal from "sweetalert2";
import "./Product.css";
// import {CommonActionTypes} from '../../redux/actiontype/commonActiontype';
// import {generatedynamicEventLinkProduct} from '../../helper/DynamicLinking';
// import Share from 'react-native-share';

const ProductDetailScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { product_id, productName } = useParams();

  const userData = useSelector((state) => state.userReducer.userData);

  const { storecartData } = useSelector((state) => state.cartReducer);

  const [allproduct, setallproduct] = useState();
  const [productDetail, setproductDetail] = useState();
  const [speceficproductDetail, setspeceficsetpodeuctDetail] = useState();
  const [selectedspecification, setselectedspecification] = useState([]);
  const [selectedProduct, setselectedProduct] = useState();
  // const [pickerspecification, setpickerspecification] = useState();

  const [selectedTab, setselectedTab] = useState("Imp Description");

  const [quantity, setquantity] = useState(1);
  const [modalshow, setmodalshow] = useState(false);
  const [selectedValue, setselectedValue] = useState();

  /////
  const [review_data, setreview_data] = useState();
  const [star, setstar] = useState();
  const [review_title, setreview_title] = useState();
  const [review_description, setreview_description] = useState();

  useEffect(() => {
    productDetailHandler();
  }, []);

  const productDetailHandler = async () => {
    try {
      const Result = await GetProductDetailById(product_id); //product_id
      // console.log(Result);
      if (Result.status == true) {
        setproductDetail(Result.data);
        setallproduct(Result.data.sub_product);

        const findProduct = Result.data.sub_product.find((item) => {
          return item.product_id == product_id;
        });

        setselectedProduct(findProduct);
        setselectedspecification(findProduct.specificationValue);
        setspeceficsetpodeuctDetail(Result.specification);
        if (Result.reviews != undefined) {
          setreview_data(Result.reviews);
        }
      } else {
        // setBannerData();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const AddTocartHandler = async () => {
    try {
      const Result = await ADDProductInCart(selectedProduct?.id);
      console.log(Result);
      if (Result.status == true) {
        dispatch(addstoreCartitem(Result.data[0]));
      } else if (Result.status == false && Result.user_id == undefined) {
        Swal.fire({
          title: "Try to login first",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(ROUTENAME.LoginSCreen);
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const validation = () => {
    if (review_title == undefined || review_title == "") {
      return "Enter review title";
    } else if (star == undefined) {
      return "Enter stars";
    } else if (review_description == undefined || review_description == "") {
      return "Enter review decsription";
    } else {
      return true;
    }
  };
  const addproductreviewHandler = async () => {
    const validate = validation();

    if (validate != true) {
      Swal.fire({
        title: validate,
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      });

      return;
    }

    try {
      const Result = await ADDProductReview(
        product_id,
        star,
        review_title,
        review_description
      );
      console.log(Result);
      if (Result.status == true) {
        setproductDetail({
          ...productDetail,
          reviews: Number(productDetail?.reviews) + 1,
        });
        setreview_data((current) => [
          ...current,
          {
            star: star,
            student_id: userData.id,
            product_id: product_id,
            status: "1",
            create_at: new Date(),
            student_name: userData.name,
            title: review_title,
            review: review_description,
          },
        ]);
      } else if (Result.status == false && Result.user_id == undefined) {
        Swal.fire({
          title: "Try to login first",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(ROUTENAME.LoginSCreen);
          }
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const FilterCart = (id) => {
    const filterCartitem = storecartData.find((itemCart) => {
      return id == itemCart.product;
    });
    // console.log(filterCartitem, id);
    return filterCartitem;
  };
  const findValue = (val) => {
    const check =
      selectedspecification.length > 0 &&
      selectedspecification.find((i) => {
        const searchValue = val.find((itemval) => {
          return itemval.id == i.value;
        });

        return searchValue;
      });
    const finalValue = val.find((itemval) => {
      return itemval.id == check.value;
    });
    return finalValue != undefined ? finalValue : "Select";
  };
  const LabelButton = ({ label, children }) => {
    return (
      <div
        onClick={() => {
          setselectedTab(label);
        }}
        style={{
          // paddingBottom: 10,
          padding: 10,
          marginBottom: 3,
          marginRight: 3,
          backgroundColor:
            label == selectedTab ? Colors.RED_COLOR : Colors.BLACK,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {children}
        <p
          style={{
            // fontFamily: Fonts.InterMedium,
            // fontSize: FontSize.BodyHeading,
            color: Colors.WHITE,
            textAlign: "center",
            verticalAlign: "center",
            margin: 0,
          }}
        >
          {label}
        </p>
      </div>
    );
  };

  const AddSpecification = (id, pickerspecification) => {
    const newArray = [...selectedspecification];
    if (pickerspecification != undefined) {
      pickerspecification.map((item) => {
        selectedspecification.find((itemval, index) => {
          if (item.id == itemval.value) {
            newArray[index] = {
              value: id,
            };
          }
        });
      });

      if (
        newArray.length == selectedProduct.length &&
        selectedProduct.specificationValue.some((item) => item.value == id) ==
          true
      ) {
        setselectedspecification(newArray);
      } else {
        const searchValue = allproduct.find((item) => {
          const chek = item.specificationValue.find((itemVal) => {
            return itemVal.value == id;
          });
          if (chek != undefined) {
            return item;
          }
        });

        setselectedProduct(searchValue);
        setselectedspecification(searchValue?.specificationValue);
      }
    }
  };

  return (
    <div className="main">
      <div className="container">
        <Row className="mt-5 mb-5">
          <Col lg={6}>
            <img
              width={"100%"}
              src={BASE_URL_IMAGE + productDetail?.image}
              alt={productDetail?.name}
            />
          </Col>
          <Col lg={6}>
            <h1>{productDetail?.name}</h1>
            <p>{htmlSpecialCharsDecode(productDetail?.description)}</p>
            <div>
              <StarContainer value={1} onvalue={productDetail?.star} />
              <StarContainer value={2} onvalue={productDetail?.star} />
              <StarContainer value={3} onvalue={productDetail?.star} />
              <StarContainer value={4} onvalue={productDetail?.star} />
              <StarContainer value={5} onvalue={productDetail?.star} />
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 10,
              }}
            >
              <p>{INRupee(selectedProduct?.final_price)}</p>
              {INRupee(selectedProduct?.price) !=
                INRupee(selectedProduct?.final_price) && (
                <p
                  style={{ marginLeft: 20, textDecorationLine: "line-through" }}
                >
                  {INRupee(selectedProduct?.price)}
                </p>
              )}
              {selectedProduct?.discount_percentage != 0 && (
                <div
                  style={{
                    backgroundColor: "red",
                  }}
                >
                  <p style={{ color: "#fff" }}>
                    % {selectedProduct?.discount_percentage}
                  </p>
                </div>
              )}
            </div>
            <div
              style={{
                height: 1,
                backgroundColor: Colors.BorderColor_3,
              }}
            />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                // marginTop: 10,
              }}
            >
              {speceficproductDetail != undefined &&
                speceficproductDetail.map((item, index) => {
                  const filterValue = findValue(item.value);

                  return (
                    <div
                      style={{
                        width: "40%",
                        marginRight: 20,
                        marginTop: 10,
                      }}
                    >
                      <p>{htmlSpecialCharsDecode(item?.specification_name)}</p>

                      <Dropdown>
                        <Dropdown.Toggle
                          variant="Secondary"
                          id="dropdown-basic"
                        >
                          {htmlSpecialCharsDecode(filterValue?.name)}
                        </Dropdown.Toggle>

                        <Dropdown.Menu role={"button"}>
                          {item.value.length > 0 &&
                            item.value.map((itemVal, index) => {
                              return (
                                <Dropdown.Item
                                  type="btn"
                                  onClick={() => {
                                    AddSpecification(itemVal.id, item.value);
                                  }}
                                >
                                  {htmlSpecialCharsDecode(itemVal.name)}
                                </Dropdown.Item>
                              );
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  );
                })}
            </div>
            <Button
              style={{
                marginTop: 10,
                width: "100%",
                height: 40,
                borderColor: Colors.RED_COLOR,
                borderWidth: 0,
                color: Colors.BLACK,
                backgroundColor: Colors.DARK_YELLOW,
              }}
              onClick={() => {
                FilterCart(selectedProduct?.id) == undefined
                  ? AddTocartHandler()
                  : navigate(ROUTENAME.CartScreen);
              }}
            >
              {FilterCart(selectedProduct?.id) == undefined
                ? "ADD TO CART"
                : "GO TO CART"}
            </Button>
          </Col>
        </Row>
        <div
          style={{
            alignSelf: "center",

            alignSelf: "center",
            backgroundColor: Colors.WHITE,
            padding: 10,
            borderRadius: 10,
            marginVertical: 10,
          }}
        >
          <div style={{ display: "flex" }}>
            <LabelButton label={"Imp Description"} width={100} />
            <LabelButton label={"Reviews"} width={100} />
          </div>
          {selectedTab == "Imp Description" ? (
            <p
              style={{
                marginTop: 20,
                color: Colors.TextSecondary,
              }}
            >
              {htmlSpecialCharsDecode(productDetail?.long_description)}
            </p>
          ) : selectedTab == "Reviews" ? (
            <ReviewsContainer
              reviewData={review_data}
              star={star}
              starHanlder={(text) => {
                setstar(text);
              }}
              review_title={review_title}
              review_titleHanlder={(text) => {
                setreview_title(text);
              }}
              review_description={review_description}
              review_descriptionHanlder={(text) => {
                setreview_description(text);
              }}
              addproductreviewHandler={() => {
                addproductreviewHandler();
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ProductDetailScreen;
