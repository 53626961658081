// import { cartActionType } from "../actiontype/cartActionType";

// const CommonState = {
//   loading: false,

//   storecartData: [],
//   storesubtotal_price: 0,
//   storetotal_price: 0,
//   storediscount_price: 0,
// };

// export default cartReducer = (state = { ...CommonState }, action) => {
//   switch (action.type) {
//     ///////////////////

//     case cartActionType.ADD_ALL_STORE_CART_DATA:
//       const ArraycartList = [...state.storecartData];

//       ArraycartList.push(...action.payload);
//       const AddsubTotalPrice = ArraycartList.reduce(
//         (previousScore, currentScore, index) =>
//           previousScore + parseInt(currentScore["product-detail"][0].price),
//         0
//       );
//       const AddedTotalPrice = ArraycartList.reduce(
//         (previousScore, currentScore, index) =>
//           previousScore +
//           parseInt(currentScore["product-detail"][0].final_price),
//         0
//       );
//       const AddedDiscountPrice = ArraycartList.reduce(
//         (previousScore, currentScore, index) =>
//           previousScore +
//           (parseInt(currentScore["product-detail"][0].price) -
//             parseInt(currentScore["product-detail"][0].final_price)),
//         0
//       );
//       return {
//         ...state,
//         storecartData: ArraycartList,
//         storesubtotal_price: AddsubTotalPrice,
//         storetotal_price: AddedTotalPrice,
//         storediscount_price: AddedDiscountPrice,
//       };

//     case cartActionType.ADD_STORE_CART_ITEM:
//       const ArraycartLists = [...state.storecartData];
//       ArraycartLists.push(action.payload);

//       const AddsubTotalPrices = ArraycartLists.reduce(
//         (previousScore, currentScore, index) =>
//           previousScore + parseInt(currentScore["product-detail"][0].price),
//         0
//       );
//       const AddedTotalPrices = ArraycartLists.reduce(
//         (previousScore, currentScore, index) =>
//           previousScore +
//           parseInt(currentScore["product-detail"][0].final_price),
//         0
//       );
//       const AddedDiscountPrices = ArraycartLists.reduce(
//         (previousScore, currentScore, index) =>
//           previousScore +
//           (parseInt(currentScore["product-detail"][0].price) -
//             parseInt(currentScore["product-detail"][0].final_price)),
//         0
//       );
//       return {
//         ...state,
//         storecartData: ArraycartLists,
//         storesubtotal_price: AddsubTotalPrices,
//         storetotal_price: AddedTotalPrices,
//         storediscount_price: AddedDiscountPrices,
//       };
//     case cartActionType.REMOVE_STORE_CART_ITEM:
//       const removecartitems = [...state.storecartData].filter((item) => {
//         return item.product != action.payload.product;
//       });
//       const RemovesubTotalPrices = removecartitems.reduce(
//         (previousScore, currentScore, index) =>
//           previousScore + parseInt(currentScore["product-detail"][0].price),
//         0
//       );
//       const RemoveTotalPrices = removecartitems.reduce(
//         (previousScore, currentScore, index) =>
//           previousScore +
//           parseInt(currentScore["product-detail"][0].final_price),
//         0
//       );
//       const RemoveDiscountPrice = removecartitems.reduce(
//         (previousScore, currentScore, index) =>
//           previousScore +
//           (parseInt(currentScore["product-detail"][0].price) -
//             parseInt(currentScore["product-detail"][0].final_price)),
//         0
//       );
//       return {
//         ...state,
//         storecartData: removecartitems,
//         storesubtotal_price: RemovesubTotalPrices,
//         storetotal_price: RemoveTotalPrices,
//         storediscount_price: RemoveDiscountPrice,
//       };
//     case cartActionType.STORE_CART_EMPTY:
//       return {
//         ...state,
//         storecartData: [],
//         storesubtotal_price: 0,
//         storetotal_price: 0,
//         storediscount_price: 0,
//       };
//     default:
//       return {
//         ...state,
//       };
//   }
// };
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  storecartData: [],
  storesubtotal_price: 0,
  storetotal_price: 0,
  storediscount_price: 0,
};

export const cartReducer = createSlice({
  name: "cartReducer",
  initialState,
  reducers: {
    addstoreCartitem: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const ArraycartLists = [...state.storecartData];
      ArraycartLists.push(action.payload);

      const AddsubTotalPrices = ArraycartLists.reduce(
        (previousScore, currentScore, index) =>
          previousScore + parseInt(currentScore["product-detail"][0].price),
        0
      );
      const AddedTotalPrices = ArraycartLists.reduce(
        (previousScore, currentScore, index) =>
          previousScore +
          parseInt(currentScore["product-detail"][0].final_price),
        0
      );
      const AddedDiscountPrices = ArraycartLists.reduce(
        (previousScore, currentScore, index) =>
          previousScore +
          (parseInt(currentScore["product-detail"][0].price) -
            parseInt(currentScore["product-detail"][0].final_price)),
        0
      );
      state.storecartData = ArraycartLists;
      state.storesubtotal_price = AddsubTotalPrices;
      state.storetotal_price = AddedTotalPrices;
      state.storediscount_price = AddedDiscountPrices;
    },
    removestoreCartitem: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      const removecartitems = [...state.storecartData].filter((item) => {
        return item.product != action.payload.product;
      });
      const RemovesubTotalPrices = removecartitems.reduce(
        (previousScore, currentScore, index) =>
          previousScore + parseInt(currentScore["product-detail"][0].price),
        0
      );
      const RemoveTotalPrices = removecartitems.reduce(
        (previousScore, currentScore, index) =>
          previousScore +
          parseInt(currentScore["product-detail"][0].final_price),
        0
      );
      const RemoveDiscountPrice = removecartitems.reduce(
        (previousScore, currentScore, index) =>
          previousScore +
          (parseInt(currentScore["product-detail"][0].price) -
            parseInt(currentScore["product-detail"][0].final_price)),
        0
      );

      state.storecartData = removecartitems;
      state.storesubtotal_price = RemovesubTotalPrices;
      state.storetotal_price = RemoveTotalPrices;
      state.storediscount_price = RemoveDiscountPrice;
    },
    addallstoreCartdata: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      const ArraycartList = [...state.storecartData];

      ArraycartList.push(...action.payload);
      const AddsubTotalPrice = ArraycartList.reduce(
        (previousScore, currentScore, index) =>
          previousScore + parseInt(currentScore["product-detail"][0].price),
        0
      );
      const AddedTotalPrice = ArraycartList.reduce(
        (previousScore, currentScore, index) =>
          previousScore +
          parseInt(currentScore["product-detail"][0].final_price),
        0
      );
      const AddedDiscountPrice = ArraycartList.reduce(
        (previousScore, currentScore, index) =>
          previousScore +
          (parseInt(currentScore["product-detail"][0].price) -
            parseInt(currentScore["product-detail"][0].final_price)),
        0
      );
      state.storecartData = ArraycartList;
      state.storesubtotal_price = AddsubTotalPrice;
      state.storetotal_price = AddedTotalPrice;
      state.storediscount_price = AddedDiscountPrice; // immutable state based off those changes
    },
    storecartEmpty: (state, actions) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.storecartData = [];
      state.storesubtotal_price = 0;
      state.storetotal_price = 0;
      state.storediscount_price = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addstoreCartitem,
  removestoreCartitem,
  addallstoreCartdata,
  storecartEmpty,
} = cartReducer.actions;

export default cartReducer.reducer;
